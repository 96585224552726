var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rounded-lg shadow-md border border-neutral-50"
  }, [_c('div', {
    staticClass: "border-b border-neutral-50 py-3 px-4 flex justify-between items-center ",
    on: {
      "click": function click() {
        _vm.visible = !_vm.visible;
      }
    }
  }, [_c('p', {
    staticClass: "font-semibold flex gap-1 items-center"
  }, [_c('span', {
    staticClass: "-ml-1"
  }, [_c('Label', {
    attrs: {
      "color": "yellow"
    }
  })], 1), _vm._v(_vm._s(_vm.title) + " ")]), _c('div', {
    staticClass: "flex items-center cursor-pointer"
  }, [_c('Chevron', {
    attrs: {
      "direction": _vm.visible ? 'up' : 'down',
      "width": "14",
      "height": "14"
    }
  })], 1)]), _c('transition', {
    attrs: {
      "enter-active-class": "transform transition duration-500 ease-custom",
      "enter-class": "-translate-y-1/2 scale-y-0 opacity-0",
      "enter-to-class": "translate-y-0 scale-y-100 opacity-100",
      "leave-active-class": "transform transition duration-300 ease-custom",
      "leave-class": "translate-y-0 scale-y-100 opacity-100",
      "leave-to-class": "-translate-y-1/2 scale-y-0 opacity-0"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }],
    staticClass: "px-4 py-3"
  }, [_vm._t("dropdown-content")], 2)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }