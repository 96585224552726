<template>
  <div class="rounded-lg shadow-md border border-neutral-50">
    <div class="border-b border-neutral-50 py-3 px-4 flex justify-between items-center " @click="
      () => {
        visible = !visible
      }
    ">
      <p class="font-semibold flex gap-1 items-center">
        <span class="-ml-1"><Label color="yellow" /></span>{{ title }}
      </p>
      <div class="flex items-center cursor-pointer">
        <Chevron :direction="visible ? 'up' : 'down'" width="14" height="14" />
      </div>
    </div>
    <transition enter-active-class="transform transition duration-500 ease-custom"
      enter-class="-translate-y-1/2 scale-y-0 opacity-0" enter-to-class="translate-y-0 scale-y-100 opacity-100"
      leave-active-class="transform transition duration-300 ease-custom"
      leave-class="translate-y-0 scale-y-100 opacity-100" leave-to-class="-translate-y-1/2 scale-y-0 opacity-0">
      <div class="px-4 py-3" v-show="visible">
        <slot name="dropdown-content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'title'
    },
    expandContentIsOpen: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Label: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Label'),
    Chevron: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Chevron')
  },
  data() {
    return {
      visible: false
    }
  },
  created() {
    this.visible = this.expandContentIsOpen
  }
}
</script>

<style></style>